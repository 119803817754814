import NavAdminData from "../../../components/layouts/AdminData/Nav_AdminData";
import { Outlet } from "react-router-dom";
import "../UserData/UserDataSharedLayouts.scss";
import LoadingDmapsLoader from "../../../components/elements/Loading/Loading_Dmaps_Loader/Loading_Dmaps_Loader";

const AdminDataSharedLayouts = () => {
  return (
    <section className="UserDataSharedLayouts">
      <div className="UserDataSharedLayouts_nav">
        <nav>
          <NavAdminData />
        </nav>
      </div>

      <div className="UserDataSharedLayouts_main">
        <LoadingDmapsLoader />
        <Outlet />
      </div>
    </section>
  )
}

export default AdminDataSharedLayouts