import Loader from "./Loader/Loader";
import LoadingDmaps from "./Loading_Dmaps/Loading_Dmaps";
import "./Loading_Dmaps_Loader.scss";

const LoadingDmapsLoader = () => {
  return (
    <div className="Loading_Dmaps_Loader">
      <div className="Loading_Dmaps_Loader_box">
        <LoadingDmaps />
        <Loader />
      </div>
    </div>
  )
}

export default LoadingDmapsLoader