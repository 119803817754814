import { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../global/GlobalContext";
import "../ColorSets/ColorSets.scss";
import useFetch_colroSet_info from "../../../hooks/Pages/UserData/useFetch_colorSet_info";
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import SearchFullResult from "../../../components/elements/Search_FullResult/Search_FullResult";
import CompaniesResult from "../../../components/pages/UserData/Companies/Companies_Result";

const Companies = () => {
  const {autoShow_company} = useContext(GlobalContext);

    const [fetch_result, setFetch_result] = useState([]);
  // new fetch
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);
  const [search, setSearch] = useState("");

    // -------------------------------
  //custom hook
  // fetch colroSets info
  
  useFetch_colroSet_info(
    "/findCompanies_listFromLast",
    "limit",
    limitResponse, // limit on page
    "page",
    page,
    setFetch_result,
    search
  );

  // autoShow new company
  useEffect(() => {
    if(autoShow_company?._id){
      setFetch_result((prev) => ({
        ...prev,
        fetch_data: [
          autoShow_company,
          ...(Array.isArray(prev.fetch_data) ? prev.fetch_data : [])
        ]
      }))
    }
  },[autoShow_company])


  console.log(fetch_result)
  // ---------------------------------------

  return (
    <div className="ColorSets">
      <div className="UserData_main">
        <section className="title">
          <h1>Společnosti</h1>
        </section>

        <section className="search">
          <SearchFullResult
            inputType="text"
            inputLabel="Vyhledat jméno společnosti"
            inputID="company_name"
            inputRegex="universal"
            state={search}
            setState={setSearch}
            wordResultKey="company_name"
            urlFetch="/findCompanies_listFromLast"
            setFetch_result={setFetch_result}
            wordResultKey2="page"
            state2={page}
            wordResultKey3="limit"
            state3={limitResponse}
          />
        </section>

        <section className="result">
          {fetch_result?.fetch_data && <CompaniesResult fetch_result={fetch_result} setFetch_result={setFetch_result} />}
        </section>
      </div>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  );
};


export default Companies