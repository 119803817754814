import { useContext } from "react";
import { GlobalContext } from "../GlobalContext";
import Header from "./mainLayouts/Header";
import Footer from "./mainLayouts/Footer";
import { Outlet, useLocation } from "react-router-dom";
import Login from "../../pages/Login";
import ErrorMsg from "./error/ErrorMsg";
import useLocalStorageGet from "../../hooks/useLocalStorageGet";
import useRefreshToken from "../../hooks/useRefreshToken";
// import useActiveToken from "../../hooks/useActiveToken";
import LoadingDmapsLoader from "../../components/elements/Loading/Loading_Dmaps_Loader/Loading_Dmaps_Loader";

import NavUserData from "../../components/layouts/UserData/NavUserData/NavUserData";

const SharedLayouts = () => {
  const { g_localStorage_loading, token_active, loading_user } = useContext(GlobalContext);
  const location = useLocation(); // Získání aktuální cesty

  console.log(loading_user)

  // load localStorage
  useLocalStorageGet();
  // Refresh token
  useRefreshToken();
  // control token is active
  // useActiveToken();

  // Kontrola, zda se nacházíme na stránce /admin_data nebo podstránkách
  const isAdminRoute = location.pathname.startsWith("/admin_data");

  return (
    <>
      {g_localStorage_loading === 1 &&
        (!token_active ? (
          <Login />
        ) : (
          <>
            {/* Header */}
            <Header />
            <ErrorMsg />
            <main>
              <section className="UserDataSharedLayouts">
                {/* Navigace se vykreslí pouze, pokud nejsme na admin route */}
                {!isAdminRoute && (
                  <div className="UserDataSharedLayouts_nav">
                    <nav>
                      <NavUserData /> {/* Zobrazí se jen mimo /admin_data */}
                    </nav>
                  </div>
                )}

                <div className="UserDataSharedLayouts_main">
                  {/* Loading */}
                  {
                    loading_user && <LoadingDmapsLoader /> 
                  }
                  <Outlet />  {/* Obsah stránek */}
                </div>
              </section>
            </main>
            <Footer />
          </>
        ))}
    </>
  );
};

export default SharedLayouts;