import { useState, useContext } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import "./OneCompany.scss";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import { f_date_only } from "../../../../../utils/date/f_date_only";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import CompanyCEODetails from "./CompanyCEO_Details/CompanyCEO_Details";
import InputLookText from "../../../../elements/inputs/InputLook_Text/InputLook_Text";
import route_universal from "../../../../../routes/global/route_universal";

const OneCompany = ({ fetch_data, setFetch_data_one, admin_create_data, setClose, setCompanies_fetch,  }) => {
  const {urlServer, setError} = useContext(GlobalContext);

  let {
    _id, 
    company,
    company_URL,
    date_create,
    coins,
    main_processor,
    CEO,
  } = fetch_data;
  
  const { name, surname, color } = admin_create_data;

  console.log(fetch_data)

  // edit company name
  const [companyName, setCompanyName] = useState(company);

  // edit company URL
  const [companyURL, setCompanyURL] = useState(company_URL);

  const [CEO_show, setCEO_show] = useState(false);
  const [main_processor_show, setMain_processor_show] = useState(false);

  // ----------------------------
  // change Company Name
  const f_changeCompanyName = async (setShowEdit, set_original) => {
    if(companyName.length > 3) {
      try {
        const result = await route_universal(
          "PATCH",
          urlServer,
          "/company_update_name",
          "company_change_ID",
          _id,
          "company_change_Name",
          companyName
        );
    
        if(result.succes){
          set_original(companyName);
          setShowEdit(false);
          
        }
  
          // set companies
          setCompanies_fetch((prev) => ({
            ...prev,
            fetch_data: prev.fetch_data.map((item) =>
              item._id === _id ? { ...item, company: companyName } : item
            ),
          }));
  
        setError(result.msg)
  
      } catch (err) {
        setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
      } 
    } else {
      setError("Jméno společnosti musí být alepsoň 4 znaky dlouhé")
    }
  }

  // Change Comapny URL
  const f_changeCompanyURL = async (setShowEdit, set_original) => {
    if(companyURL.length > 10) {
      try {
        const result = await route_universal(
          "PATCH",
          urlServer,
          "/company_update_url",
          "update_company_ID",
          _id,
          "update_company_URL",
          companyURL
        );
    
        if(result.succes){
          set_original(companyURL);
          setShowEdit(false);
             
          
          // set companies
          setFetch_data_one((prev) => ({
            ...prev,
            fetch_data: {
              ...prev.fetch_data,
              company_URL: companyURL // Aktualizace pouze company_URL
            }
          }));
        } 
        
        setError(result.msg) 
  
      } catch (err) {
        setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
      } 
    } else {
      setError("URL společnosti musí být alepsoň 11 znaků dlouhé")
    }
  }

  // ----------------------------
  // Replace CEO + mainprocesor object values
  const reorderObject = (obj, keysOrder) => {
    const reordered = {};
    keysOrder.forEach((key) => {
      if (key in obj) {
        reordered[key] = obj[key];
      }
    });
    return reordered;
  };

  // Definujte pořadí klíčů
  const mainProcessorOrder = [
    "main_processor_Name",
    "main_processor_Surname",
    "main_processor_Email",
    "main_processor_Phone",
    "main_processor_Code",
    "main_processor_Code_active",
  ];

  const CEOOrder = [
    "CEO_Name",
    "CEO_Surname",
    "CEO_Email",
    "CEO_Phone",
    "CEO_Code",
    "CEO_Code_active",
  ];

  // Uspořádejte objekty podle pořadí
  const reorderedMainProcessor = reorderObject(
    main_processor,
    mainProcessorOrder
  );
  const reorderedCEO = reorderObject(CEO, CEOOrder);

  // -----------------------------------

  return (
    <div className="OneCompany">
      <div className="popUp_full_window">
        {/* BTN Close */}
        <BtnClose setClose={setClose} />

        {/* Title */}
        <div className="popUp_full_title">
          <h2>Společnost</h2>
        </div>

        {/* Main */}
        <div className="popUp_full_main">
          <section className="company_data">
            <div 
              className="company_Name_box" 
              title="Editovat jméno společnosti"
            >
              <InputLookText
                  type="text"
                  label="Editace jména společosti"
                  id="companyName_change_input"
                  value={companyName}
                  set={setCompanyName}
                  regexSetting="universal"
                  p_text={company}
                  btnText="Editovat"
                  btnFunction={f_changeCompanyName}
                  original_text={company}
                  // show={edit_companyName}
                  // setShow={setEdit_companyName}
                />
            </div>
            {/* Comapny Details */}
            <div className="company_data_details1">
              <div className="item" style={{ borderColor: color }}>
                <p className="detail_title">Založil uživatel</p>
                <p className="detail_text">
                  {name} {surname}
                </p>
              </div>
              <div className="item">
                <p className="detail_title">Datum vytvoření</p>
                <p className="detail_text" title={f_date_to_cz(date_create)}>
                  {f_date_only(date_create)}
                </p>
              </div>
              <div 
                className="item URL_box" 
                // onClick={() => setEdit_companyURL(true)}
                title="Editovat URL společnosti"
              >
                {/* <p className="detail_title">URL</p> */}
                {/* <p className="detail_text">{company_URL}</p> */}
                <InputLookText
                  type="text"
                  // label="Editace URL společosti"
                  id="companyURL_change_input"
                  value={companyURL}
                  set={setCompanyURL}
                  regexSetting="URL"
                  p_text={companyURL}
                  btnText="Editovat"
                  btnFunction={f_changeCompanyURL}
                  original_text={company_URL}
                  labelBox="URL"
                  // show={edit_companyURL}
                  // setShow={setEdit_companyURL}
                />
              </div>
              <div className="item">
                <p className="detail_title">Zbývající počet coinů</p>
                <p className="detail_text">{coins}</p>
              </div>
            </div>
            {/* CEO + main_processor */}
            <div className="company_data_CEO">
              {
                // CEO
                CEO.CEO_Name && (
                  <CompanyCEODetails
                    text="CEO"
                    data={reorderedCEO}
                    value={CEO_show}
                    set={setCEO_show}
                  />
                )
              }
              {
                // main processor
                main_processor.main_processor_Name && (
                  <CompanyCEODetails
                    text="kontaktní osobu"
                    data={reorderedMainProcessor}
                    value={main_processor_show}
                    set={setMain_processor_show}
                  />
                )
              }
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default OneCompany;
