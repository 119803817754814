import { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../../../global/GlobalContext";
import SelectMore from "../../SelectMore/SelectMore";
import route_universal from "../../../../../routes/global/route_universal";

const SelectTagFindAllName = ({ setTagSelect }) => {
  const { urlServer, setError } = useContext(GlobalContext);

  const [fetch_data, setFetch_data] = useState([]); // tags options
  // ---------------------------------------------------
  // hook
  // auto find Tag Name
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await route_universal(
          "POST",
          urlServer,
          "/tag_findAllName"
        );

        console.log(response);

        if (response.success) {
          setFetch_data(response.fetch_data);
        } else {
          setError(response.msg);
        }
      } catch (err) {
        setError("Client - Catch: " + err);
      }
    };

    if (fetch_data.length === 0) {
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch_data]);

  // ---------------------------------------------------
  return (
    <div>
      <SelectMore
        object={fetch_data || []}
        onChange={(selectedOption) => {
          if (selectedOption && selectedOption.label) {
            const nameOnly = selectedOption.label.split(" || ")[0]; // Rozdělí text na jméno a společnost, vezme jen jméno
            setTagSelect({
              value: selectedOption.value, // Ponechává hodnotu value z původního objektu
              label: nameOnly, // Nastavuje jen jméno do labelu
              index: selectedOption.index, // Ponechává index z původního objektu
            });
          } else {
            setTagSelect({
              value: "", // Nebo nastavte hodnotu dle potřeby
              label: "",
              index: -1, // Nebo jiný indikátor chyby
            });
          }
        }}
        placeholder="Vyberte Tag"
      />
    </div>
  );
};

export default SelectTagFindAllName;
