import { useState } from "react";
import { FaTrash } from "react-icons/fa";
import "./Btn_Delete.scss";

import BtnDeleteConfirmation from "./Btn_Delete_Confirmation/Btn_Delete_Confirmation";

const BtnDelete = ({
  title,
  h1,
  name,
  text,
  btn_yes,
  // delete_dataList,
  // setDelete_dataList,
}) => {
  const [show_conformation, setShow_conformation] = useState(false);

  return (
    <div className="Btn_Delete">
      {show_conformation && (
        <BtnDeleteConfirmation
          h1={h1}
          name={name}
          text={text}
          btn_no={() => setShow_conformation(false)}
          btn_yes={() => btn_yes(setShow_conformation)}
          // delete_dataList={delete_dataList}
          // setDelete_dataList={setDelete_dataList}
        />
      )}

      <button className="btnDelete"  onClick={() => setShow_conformation(true)}>
        <FaTrash title={title} />
      </button>
      
    </div>
  );
};

export default BtnDelete;
