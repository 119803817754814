import React from "react";
import "./BtnShowColorSet_PopUpColorSet.scss";
import OneColorSetGrafData from "../../../../UsersData_Result/UsersData_Result_GrafData/OneColorSet_GrafData/OneColorSet_GrafData";
import BtnClose from "../../../../../../elements/btn/BtnClose/BtnClose";

const BtnShowColorSet_PopUpColorSet = ({
  setShow,
  fetch_data,
  setFetch_data,
  name,
  setFetch_result,
  // delet user
  setFetch_result_list,
  fetch_result,
  URLValue, 
  setURLValue, 
  userName, 
  setUserName,
  // - switch to another tag - tag list 
  setFetch_tags,
  tag_ID,
  setFetch_respondents_in_tag,
  // - page colorSet - over tag - switch to another tag
  setFetch_result_ColorSet_list,
}) => {
  const btnClose = () => {
    setFetch_data({});
    setShow(false);
  };

  return (
    <div className="BtnShowColorSet_PopUpColorSet">
      <div className="BtnShowColorSet_PopUpColorSet_main">
        {/* btn close */}
        <BtnClose setClose={btnClose} />

        <section className="BtnShowColorSet_PopUpColorSet_main_response">
          {fetch_data._id && (
            <OneColorSetGrafData
              colorSet={fetch_data}
              name={name}
              setFetch_result={setFetch_result}
              setShow={setShow}
              setFetch_result_list={ setFetch_result_list}
              fetch_result={fetch_result}
              URLValue={URLValue}
              setURLValue={setURLValue}
              userName={userName}
              setUserName={setUserName}
              // - switch to another tag - tag list 
              setFetch_tags={setFetch_tags}
              tag_ID={tag_ID}
              setFetch_respondents_in_tag={setFetch_respondents_in_tag}
              // - page colorSet - over tag - switch to another tag
              setFetch_result_ColorSet_list={setFetch_result_ColorSet_list}
            />
          )}
        </section>
      </div>
    </div>
  );
};

export default BtnShowColorSet_PopUpColorSet;
