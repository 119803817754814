import { useReducer, createContext } from "react";
import GlobalReducer from "./GlobalReducer";

const mainState = { // Global Reducer - Defaultni state
  // LocalStorage loading
  g_localStorage_loading: 0,

  // Token
  // token: "",
  token_active: false,
  active_time: 0,
  // tokenActive_ceateTime: 0,

  // Admin
  admin_name: "",
  admin_surname: "",
  admin_phone: "",
  admin_gender: "",
  admin_email: "",
  admin_rank: "",
  admin_color: "",
  admin_position_name: "",
  admin_company_Name: "",

  // addWordsResult
  refresh_setWords: false,

  // - autoShow
  // - - newRespondent
  autoShow_newRespondent: {},
  // - - tag
  autoShow_tag: {},
  // - - company
  autoShow_company: {},

  // // popUp
  // popUp: false,
  //Error
  error: "",

  // loading
  loading_user: false,
  loading_admin: false,
  loading_tagUsers: false,
  
  // urlServer: "http://localhost:5000" // local
  urlServer: "/api", // DMAPS
  //  urlServer: "https://novitim-admin-70a2347da0c7.herokuapp.com" // Heroku
};

export const GlobalContext = createContext(); // Context - create

// -----------------------------------------------------------------------------------------

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, mainState);


  // localStorage loading
  const setG_localStorage_loading = (g_localStorage_loading) => {
    dispatch({
      type: "SET_G_LOCALSTORAGE_LOADING",
      payload: g_localStorage_loading,
    });
  };

  // token
  // const setToken = (token) => {
  //   dispatch({
  //     type: "SET_TOKEN",
  //     payload: token,
  //   });
  // };
  const setToken_active = (token_active) => {
    dispatch({
      type: "SET_TOKEN_ACTIVE",
      payload: token_active
    })
  }
  const setActive_time = (active_time) => {
    dispatch({
      type: "SET_ACTIVE_TIME",
      payload: active_time
    })
  }
  // const setTokenActive_ceateTime = (tokenActive_ceateTime) => {
  //   dispatch({
  //     type: "SET_TOKEN_ACTIVE_CREATE_TIME",
  //     payload: tokenActive_ceateTime
  //   })
  // }


  // Admin
  const setAdmin = (admin_name, admin_surname, admin_phone, admin_gender, admin_email, admin_rank, admin_color, admin_position_name, admin_company_Name) => {
    dispatch({
      type: "SET_ADMIN",
      payload: {
        admin_name,
        admin_surname,
        admin_phone,
        admin_gender, 
        admin_email, 
        admin_rank,
        admin_color,
        admin_position_name,
        admin_company_Name,
      }
    })
  }


  // TO:DO - je třeba?
  const setRefresh_setWords = (refres_setWords) => {
    dispatch({
      type: "SET_REFRESH_SET_WORDS",
      payload: refres_setWords
    })
  }

   // - autoShow
   // - -  - newRespondent (autoShow)
  const setAutoShow_newRespondent = (autoShow_newRespondent) => {
    dispatch({
      type: "SET_AUTO_SHOW_NEW_RESPONDENT",
      payload: autoShow_newRespondent
    })
  }
  // - - tag (autoShow)
  const setAutoShow_tag = (autoShow_tag) => {
    dispatch({
      type: "SET_AUTO_SHOW_TAG",
      payload: autoShow_tag
    })
  }
  // - - company
  const setAutoShow_company = (autoShow_company) => {
    dispatch({
      type: "SET_AUTO_SHOW_COMPANY",
      payload: autoShow_company
    })
  }

  // // PopUp
  // const setPopUp = (popUp) => {
  //   dispatch({
  //     type: "SET_POP_UP",
  //     payload: popUp
  //   })
  // }

  //Error
  const setError = (error) => {
    dispatch({
      type: "SET_ERROR",
      payload: error
    })
  }

  // Loading
  const setLoading_user = (loading_user) => {
    dispatch({
      type: "SET_LOADING_USER",
      payload: loading_user
    })
  }
  const setLoading_admin = (loading_admin) => {
    dispatch({
      type: "SET_LOADING_ADMIN",
      payload: loading_admin
    })
  }
  const setLoading_tagUsers = (loading_tagUsers) => {
    dispatch({
      type: "SET_LOADING_TAG_USERS",
      payload: loading_tagUsers
    })
  }

  // ---------------------------------------------------------------------------------

  return (
    <GlobalContext.Provider // Context - extract
      value={{ // Reduce for context
        
        // load localStorage
        setG_localStorage_loading,
        g_localStorage_loading: state.g_localStorage_loading,

        // token
        // setToken,
        // token: state.token,
        setToken_active,
        token_active: state.token_active,

        // Active_time - refresh
        setActive_time,
        active_time: state.active_time,

        // setTokenActive_ceateTime,
        // tokenActive_ceateTime: state.tokenActive_ceateTime,

        // Admin
        setAdmin,
        admin_name: state.admin_name, 
        admin_surname: state.admin_surname,
        admin_phone: state.admin_phone,
        admin_gender: state.admin_gender,
        admin_email: state.admin_email, 
        admin_rank: state.admin_rank,
        admin_color: state.admin_color,
        admin_position_name: state.admin_position_name,
        admin_company_Name: state.admin_company_Name,


        setRefresh_setWords,
        refres_setWords: state.refres_setWords,

        // - autoShow
        // - - newRespondent (autoShow)
        setAutoShow_newRespondent,
        autoShow_newRespondent: state.autoShow_newRespondent,
        // - - tag (autoShow)
        setAutoShow_tag,
        autoShow_tag: state.autoShow_tag,
        // - - company (autoShow)
        setAutoShow_company,
        autoShow_company: state.autoShow_company,

        //popUp
        // setPopUp,
        // popUp: state.popUp,

        //error
        setError,
        error: state.error,

        // loading
        setLoading_user,
        loading_user: state.loading_user,
        setLoading_admin,
        loading_admin: state.loading_admin,
        setLoading_tagUsers,
        loading_tagUsers: state.loading_tagUsers,

        urlServer: state.urlServer,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
