import { useState } from "react";
import "../../../completeComponents/UserData_listFrom_last_result/UserData_listFrom_last_result.scss";
import { f_date_to_cz } from "../../../../utils/date/f_date_to_cz";
import { f_date_only } from "../../../../utils/date/f_date_only";
import f_initials from "../../../../utils/nameAndEmail/f_initials";
import BtnClose from "../../../elements/btn/BtnClose/BtnClose";
import EmailIconsInfo from "../Tags/Tag_Users/Tag_Users_Response/email/Email_Icons_Info";
import NumberAttempts from "../../../elements/number_attempts/Number_Attempts";
import { useOneNewRespondent } from "../../../../hooks/newRespondent/useOneNewRespondent";
import NewRespondentOneRespondent from "../../../global/NewRespondent/NewRespondent_oneRespondent";
import TagUsers from "../Tags/Tag_Users/Tag_Users";

const NewRespondentResult = ({ fetch_result, setFetch_result }) => {
  console.log(fetch_result)
  // show email
  const [showEmail, setShowEmail] = useState({});
  // tagID
  const [selectedTagID, setSelectedTagID] = useState(null);
  // one Respondent
  const [newRespondent_ID, setNewRespondent_ID] = useState({});

  // -----------

  // Custom hook
  // newRespondent
  const [fetch_data_newRespondent, setFetch_data_newRespondent] = useOneNewRespondent(newRespondent_ID.ID);

  // -----------

  const btn_email = (id) => {
    setShowEmail((prevState) => ({
      ...prevState,
      [id]: !prevState[id], // Přepnutí zobrazení emailu pro konkrétní ID
    }));
  };

  // -----------

  return (
    <div className="UserData_listFrom_last_result">
      {/* one respondent */}
      {newRespondent_ID.ID && fetch_data_newRespondent?.fetch_data?._id && (
        <NewRespondentOneRespondent
          fetch_data_newRespondent={fetch_data_newRespondent}
          setFetch_data_newRespondent={setFetch_data_newRespondent}
          setFetch_result={setFetch_result}
          setNewRespondent_ID={setNewRespondent_ID}
        />
      )}
      {/* POP UP TagUSers */}
      {selectedTagID?._id && (
        <TagUsers 
          tagData={selectedTagID} 
          setSelectedTagID={setSelectedTagID} 
          setFetch_result_NewRespondent_list={setFetch_result}
        />
      )}

      {fetch_result.fetch_data && (
        <div className="colorSets">
          <div className="titles">
            <p className="nameEmail">Jméno</p>
            <p>Tag</p>
            <p>Společnost</p>
            <p>Slovní modul</p>
            <p>Datum vytvoření</p>
            <p className="item_last_text">Aktuální stav</p>
          </div>
          {fetch_result.fetch_data.map((oneColorSet_info) => {
            const {
              _id,
              name,
              email,
              //   code,
              number_attempts,
              date_create,
              tag,
              tag_color,
              tag_adminName,
              wordSet,
              admin_create,
              company,
              // - email no send
              noEmail_send,
              // - email
              email_link_click,
              email_open,
              email_delivered,
              email_bounced,
              email_spam_reported,
              email_dropped,
              email_deferred,
            } = oneColorSet_info;

            const { tag_ID, tag_Name } = tag || {};
            const { /*wordSet_ID, */ wordSet_Name } = wordSet;
            const { /*admin_ID, */ admin_Name } = admin_create || {};
            const { /*company_ID, */ company_Name } = company || {};

            return (
              <div key={_id} className="oneColorSet_info">
                <div className="nameEmail">
                  <p className="name">{name}</p>
                  {/* Tlačítko pro zobrazení emailu */}
                  <button className="email_btn" onClick={() => btn_email(_id)}>
                    E-mail
                  </button>
                  {/* Zobrazení emailu podle stavu pro konkrétní ID */}
                  {showEmail[_id] && (
                    <p className="email" /*onClick={() => btn_email(_id)} */>
                      {email} <BtnClose setClose={() => btn_email(_id)} />
                    </p>
                  )}
                </div>
                <div className="item">
                  <div
                    className="tag_box"
                    style={{ backgroundColor: tag_color }}
                    title={admin_Name}
                    onClick={() =>
                      setSelectedTagID({
                        admin_color: tag_color,
                        admin_name: tag_adminName,
                        company_Name,
                        date_create,
                        name: tag_Name,
                        _id: tag_ID,
                      })
                    }
                  >
                    <p className="tag_name">{f_initials(admin_Name)}</p>
                    <p className="tag">{tag_Name}</p>
                  </div>
                </div>
                <div
                  className="open_set"
                  onClick={() => setNewRespondent_ID({ ID: _id, name })}
                >
                  <div className="item">{company_Name}</div>
                  <div className="item">{wordSet_Name}</div>
                  <div className="item" title={f_date_to_cz(date_create)}>
                    {f_date_only(date_create)}
                  </div>
                  <div className="item item_last">
                    {/* number attempts */}
                    {number_attempts < 2 && (
                      <NumberAttempts number_attempts={number_attempts} />
                    )}
                    {/* Email icons */}
                    {number_attempts === 2 && (
                      <EmailIconsInfo
                        noEmail_send={noEmail_send}
                        email_link_click={email_link_click}
                        email_open={email_open}
                        email_delivered={email_delivered}
                        email_bounced={email_bounced}
                        email_spam_reported={email_spam_reported}
                        email_dropped={email_dropped}
                        email_deferred={email_deferred}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NewRespondentResult;
