import React from 'react'
import "./UserDataHome.scss"

const UserDataHome = () => {
  return (
    <div className="UserDataHome">

    </div>
  )
}

export default UserDataHome