import { useContext } from 'react'
import { GlobalContext } from '../../../../../../../global/GlobalContext'
import route_post_universal from '../../../../../../../routes/global/route_post_universal'
import "./BtnSendEmail_NewRespondent.scss"

const BtnSendEmail_NewRespondent = ({
  gender, 
  name, 
  surname, 
  addressing, 
  email, 
  wordSet_select, 
  selected_TagData, 
  tagSelect, 
  setNewRespondent, 
  emailForm,
  // tag profession
  tag_profesion_Name,
  tag_profesion_URL,
}) => {
    const { 
      urlServer, 
      setError,
      // - autoShow - newRespondent
      admin_name, 
      admin_surname,
      setAutoShow_newRespondent 
    } = useContext(GlobalContext)

  // send email - newRespondent
  const send_email_newRespondent = async () => {
    try {
      if(!emailForm) {
        return setError("Nevybrána forma e-mailu")
      }
      if (emailForm === "2" || emailForm === "3") {
        if (!tag_profesion_Name){
          return setError("Nevyplněno jméno profese")
        } else if (!tag_profesion_URL) {
          return setError("Nevyplněno URL profese")
        }
      }
  
      const response = await route_post_universal(
        urlServer,
        "/newRespondent_emailSend",
        "gender_resp",
        gender,
        "name_resp",
        name,
        "surname_resp",
        surname,
        "addressing_resp",
        addressing,
        "email_resp",
        email,
        "tag_ID",
        tagSelect.value,
        "tag_Name",
        tagSelect.label,
        "wordSet_ID",
        wordSet_select.value,
        "wordSet_Name",
        wordSet_select.label,
  
        "respondent_company_ID",
        selected_TagData.company.company_ID,
        "respondent_company_Name",
        selected_TagData.company.company_Name,
        "respondent_company_URL",
        selected_TagData.company.company_URL,
        "tag_profesion_Name",
        tag_profesion_Name,
        "tag_profesion_URL",
        tag_profesion_URL,
        "emailForm",
        emailForm,
      )
  
      console.log(response)
  
  
      if(response.success){
        setNewRespondent(false)
        // GLobalContext - newRespondent
        setAutoShow_newRespondent({
          _id: response.newRespondent_ID,
          admin_create: {
            // admin_ID
            admin_Name: `${admin_name} ${admin_surname}`,
          },
          company: {
            company_ID: selected_TagData.company.company_ID,
            company_Name: selected_TagData.company.company_Name,
          },
          date_create: Date.now(),
          name: `${name} ${surname}`,
          noEmail_send: false,
          number_attempts: 2,
          tag:{
            tag_ID: tagSelect.value,
            tag_Name: tagSelect.label,
            tag_profesion_Name,
            tag_profesion_URL,
          },
          tag_adminName: `${response.tag_adminData.name} ${response.tag_adminData.surname}`,
          tag_color: response.tag_adminData.color,
          wordSet:{
            wordSet_ID: wordSet_select.value,
            wordSet_Name: wordSet_select.label,
          }
        })
      } 
  
      setError(response.msg)

    } catch (err) {
      setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
    } 
  }

  // - No Send Email - New Respondent
  const newRespondent_noSendEmail = async () => {
    console.log(selected_TagData)
    const response = await route_post_universal(
      urlServer,
      "/newRespondent_noEmailSend",
      "gender_resp",
      gender,
      "name_resp",
      name,
      "surname_resp",
      surname,
      "addressing_resp",
      addressing,
      "email_resp",
      email,
      "tag_ID",
      tagSelect.value,
      "tag_Name",
      tagSelect.label,
      "wordSet_ID",
      wordSet_select.value,
      "wordSet_Name",
      wordSet_select.label,

      "respondent_company_ID",
      selected_TagData.company.company_ID,
      "respondent_company_Name",
      selected_TagData.company.company_Name,
    )

    if(response.success){
      setNewRespondent(false)
      // GLobalContext - newRespondent
      setAutoShow_newRespondent({
        _id: response.newRespondent_ID,
        admin_create: {
          // admin_ID
          admin_Name: `${admin_name} ${admin_surname}`,
        },
        company: {
          company_ID: selected_TagData.company.company_ID,
          company_Name: selected_TagData.company.company_Name,
        },
        date_create: Date.now(),
        name: `${name} ${surname}`,
        noEmail_send: true,
        number_attempts: 2,
        tag:{
          tag_ID: tagSelect.value,
          tag_Name: tagSelect.label,
          tag_profesion_Name,
          tag_profesion_URL,
        },
        tag_adminName: `${response.tag_adminData.name} ${response.tag_adminData.surname}`,
        tag_color: response.tag_adminData.color,
        wordSet:{
          wordSet_ID: wordSet_select.value,
          wordSet_Name: wordSet_select.label,
        }
      })
    } 

    setError(response.msg)
  }

  return (
    <div className="BtnSendEmail_NewRespondent">
      <button onClick={newRespondent_noSendEmail} className="normalButton">Vytvořit respondenta</button>
      <button onClick={send_email_newRespondent} className="normalButton">Vytvořit respondenta a odeslat e-mail</button>
    </div>
  )
}

export default BtnSendEmail_NewRespondent