import { useState, useContext } from "react";
import { GlobalContext } from "../../../../global/GlobalContext";
import "./UsersDataResult.scss";
import languages_name from "../../../../data/user-data/language/languages_name";
import route_post_universal from "../../../../routes/global/route_post_universal";
import UserDataResultNumberColorSets from "./UserData_Result_NumberColorSets/UserData_Result_NumberColorSets";

const UsersDataResult = ({
  oneUser,
  setUserName,
  // delete user
  setFetch_result,
  fetch_result,
  URLValue, 
  setURLValue, 
  userName, 
}) => {
  const { setError, urlServer } = useContext(GlobalContext);

  const [fetch_colorSet, setFetch_colorSet] = useState(null);
  // limiter + page
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);

  // actual year
  const currentYear = new Date().getFullYear();

  const {
    _id,
    name,
    email,
    language,
    gender,
    species,
    country: { countryName },
    region,
    usaRegion,
    years,
    education: { educationName },
    profession: { professionName },
    maritalStatus: { maritalStatusName },
    specialization: { specialization_Name },
    personsInTheHousehold,
  } = oneUser;

  console.log(fetch_colorSet);

  const { usaRegionName } = usaRegion || {};

  // Gender
  const genderOptions = ["Muž", "Žena", "Ostatní"];
  let genderText = genderOptions[gender];

  // Language
  const languageName = languages_name.find((l) => l.code === language)?.name;

  // Species
  if (species !== null && species >= 0) {
    const speciesOptions = ["Mužský", "Ženský"];
    const speciesText = speciesOptions[species];
    genderText = `${genderText} - ${speciesText}`;
  }

  // Show ColorSet Data
  const btn_showColroSetData = async () => {
    const result = await route_post_universal(
      urlServer,
      "/find_number_users_colorSets",
      "userID",
      _id,
      "page",
      page,
      "limit",
      limitResponse
    );

    console.log(result);

    if (result?.fetch_data.length > 0) {
      setFetch_colorSet(result); // Nastaví data pro tohoto uživatele
      console.log("Data loaded for user:", _id);
    } else {
      setError(result.msg);
    }
  };

  return (
    <div className="Result_fetchData">
      <div className="one">
        <div className="name">
          <div className="main_data">
            <button className="btnName" onClick={() => setUserName(name)}>
              {name}
            </button>
            <p className="email">{email}</p>
          </div>

          {!fetch_colorSet && (
            <div className="buttons">
              <button className="btn_showGraf" onClick={btn_showColroSetData}>
                Načíst Vyplněné diagnostiky
              </button>
            </div>
          )}
        </div>

        {/* User Details */}
        <div className="details">
          <div className="row">
            <div className="item">
              <p className="title_item">Jazyk: </p>
              <p className="language">{language + " - " + languageName}</p>
            </div>
            <div className="item">
              <p className="title_item">Rok narození: </p>
              <p title={currentYear - years}>{years}</p>
            </div>
            <div className="item">
              <p className="title_item">Pohlaví: </p>
              <p className="gender">{genderText}</p>
            </div>
          </div>

          <div className="row">
            <div className="item">
              <p className="title_item">Vzdělání: </p>
              <p>{educationName}</p>
            </div>
            <div className="item">
              <p className="title_item">Rodinný stav: </p>
              <p>{maritalStatusName}</p>
            </div>
            <div className="item">
              <p className="title_item">Počet osob v domácnosti: </p>
              <p>
                {personsInTheHousehold + 1 === 10
                  ? personsInTheHousehold + 1 + "+ "
                  : personsInTheHousehold + 1}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="item">
              <p className="title_item">Země: </p>
              <p>{countryName}</p>
            </div>
            <div className="item">
              <p className="title_item">Region: </p>
              <p>{region}</p>
            </div>
            {usaRegionName && (
              <div className="item">
                <p className="title_item">USA region: </p>
                <p>{usaRegionName}</p>
              </div>
            )}
          </div>

          <div className="row">
            <div className="item">
              <p className="title_item">Obor</p>
              <p>{specialization_Name}</p>
            </div>
            <div className="item">
              <p className="title_item">Profese: </p>
              <p>{professionName}</p>
            </div>
          </div>
        </div>

        {/* Show Graf Data - only for that user */}
        {fetch_colorSet && (
          <UserDataResultNumberColorSets
            fetch_colorSet={fetch_colorSet}
            name={name}
            setFetch_result={setFetch_colorSet}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
            // userList for delete One
            setFetch_result_list={setFetch_result}
            fetch_result={fetch_result}
            URLValue={URLValue}
            setURLValue={setURLValue}
            userName={userName}
            setUserName={setUserName}
          />
        )}
      </div>
    </div>
  );
};

export default UsersDataResult;
