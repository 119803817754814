import React from 'react'
import logo from "../../../../../img/logo/dmaps/logo_dmaps_orange.png"
import "./Loading_Dmaps.scss";

const LoadingDmaps = () => {
  return (
    <div className="Loading_Dmaps">
        <img src={logo} alt="Loading" />
    </div>
  )
}

export default LoadingDmaps