import { useEffect, useRef, useState } from "react";
import f_input_regex from "../../../../utils/elements/regex/f_input_regex";
import "./InputLook_Text.scss";

const InputLookText = ({
  type,
  label,
  id,
  value,
  set,
  regexSetting,
  btnText,
  btnFunction,
  original_text,
  // setShow,
  autoComplete,
  readOnly,
  labelBox,
  title
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [original, setOriginal] = useState(original_text);

  const ref = useRef(null);
  const inputChange = (value) => {
    if (f_input_regex(regexSetting, value)) {
      set(value);
    }
  };

  // --------------------------
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowEdit(false);
      }
    };

    // Přidáme event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Úklid při odmontování komponenty
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowEdit, original_text]);

  // ----------------------------
  const f_companyNameChange = () => {
    setShowEdit(true);
  };
  // -----------------------------

  return (
    <div className="InputLook_Text" 
      ref={ref} 
      onClick={f_companyNameChange}
      title={title}
      >
      <div className="label_button">
        {labelBox && <p className="labelBox">{labelBox}</p>}
        {showEdit && (
          <label
            htmlFor={id}
            className="labelBox"
            style={
              value
                ? {
                    // top: "-5px",
                    // left: "20px",
                    fontSize: "15px",
                    textAlign: "center",
                  }
                : {}
            }
          >
            {label}
          </label>
        )}

        {showEdit && (
          <button
            className="btn_edit"
            onClick={() => btnFunction(setShowEdit, setOriginal)}
          >
            {btnText}
          </button>
        )}
      </div>

      <div className="edit">
        {showEdit ? (
          <>
            <div className="intput_box">
              {/* <label
                htmlFor={id}
                style={
                  value
                    ? {
                        top: "-5px",
                        left: "20px",
                        fontSize: "15px",
                      }
                    : {}
                }
              >
                {label}
              </label> */}

              <input
                type={type}
                id={id}
                onChange={(e) => inputChange(e.target.value)}
                value={value}
                autoComplete={autoComplete}
                readOnly={readOnly}
              />
            </div>
          </>
        ) : (
          <p className="original_text">{original}</p>
        )}
      </div>
    </div>
  );
};

export default InputLookText;
