import { useEffect, useContext } from "react";
import InputContainer from "../inputs/InputContainer/InputContainer";
import "../Search/Search.scss";
import route_post_universal from "../../../routes/global/route_post_universal";
import { GlobalContext } from "../../../global/GlobalContext";

const SearchFullResult = ({
  inputType,
  inputLabel,
  inputID,
  inputRegex,
  state,
  setState,
  // urlServer,
  wordResultKey,
  urlFetch,
  // fetch_result,
  setFetch_result,
  wordResultKey2,
  state2,
  wordResultKey3,
  state3,
}) => {
    const { urlServer, setError, setLoading_user } = useContext(GlobalContext);
    // Search
    useEffect(() => {
        const f_fetchData = async () => {
          setLoading_user(true)
          try {
            // setFetch_result([]); // správná funkce pro nastavení stavu
            if (state.length >= 2) {
              const result = await route_post_universal(urlServer, urlFetch, wordResultKey, state, wordResultKey2, state2, wordResultKey3, state3)
              if (result.fetch_data) {
                setFetch_result(result); // správná funkce pro nastavení stavu
              } else {
                setFetch_result([]);
                setError(result.msg);
              }
            }
          } catch(err) {

          } finally {
            setLoading_user(false)
          }
            
          };
      
          if(urlFetch !== "" && state.length >= 2) {
            f_fetchData();
          } 
      
          // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state, state2, state3])

  return (
    <div className="Search">
      <div className="Search_box">
        <InputContainer
          type={inputType}
          label={inputLabel}
          id={inputID}
          regexSetting={inputRegex}
          value={state}
          set={setState}
          autocomplete="off"
        />
      </div>
    </div>
  );
};

export default SearchFullResult;