import { useState } from "react";
// import "./ColorSets.scss";
// import Search from "../../../components/elements/Search/Search";
// import route_findUserAndColorSet from "../../../routes/global/route_post_universal";
// new
import useFetch_colroSet_info from "../../../hooks/Pages/UserData/useFetch_colorSet_info";
import UserDataListFromLastResult from "../../../components/pages/UsersData/UserData_listFromLast/UserData_listFromLast_result/UserData_listFromLast_result";
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import SearchFullResult from "../../../components/elements/Search_FullResult/Search_FullResult";


const ColorSets = () => {

  const [fetch_result, setFetch_result] = useState([]);

  // new fetch
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);
  const [search, setSearch] = useState("");

  // -------------------------------
  // custom hook
  // fetch colroSets info
  useFetch_colroSet_info(
    "/findColorSets_listFromLast",
    "limit",
    limitResponse, // limit on page
    "page",
    page,
    setFetch_result,
    search
  );

  // ---------------------------------------

  return (
    <div className="page">
      <div className="UserData_main">
        <section className="title">
          <h1>Vyplněné diagnostiky</h1>
        </section>

        <section className="search">
          <SearchFullResult
            inputType="text"
            inputLabel="Vyhledat jméno vyplněného respondenta"
            inputID="colorSet_name"
            inputRegex="universal"
            state={search}
            setState={setSearch}
            wordResultKey="colorSet_name"
            urlFetch="/findColorSets_listFromLast"
            setFetch_result={setFetch_result}
            wordResultKey2="page"
            state2={page}
            wordResultKey3="limit"
            state3={limitResponse}
          />
        </section>

        <section className="result">
          <UserDataListFromLastResult 
            fetch_result={fetch_result} 
            setFetch_result={setFetch_result} 
          />
        </section>
      </div>

      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  );
};

export default ColorSets;
