import React from 'react'
import "./Btn_Delete_Confirmation.scss";

const BtnDeleteConfirmation = ({ 
  h1, 
  name, 
  text, 
  btn_no, 
  btn_yes, 

}) => {
  return (
    <div className="Btn_Delete_Confirmation">
        <div className="Btn_Delete_Confirmation_windows">
            <h1>{h1}</h1>
            <h2>{name}</h2>
            <p>{text}</p>
            <div className="delete_btn_box">
                <button onClick={btn_no}>Ne</button>
                <button onClick={btn_yes}>Ano</button>
            </div>
        </div>
    </div>
  )
}

export default BtnDeleteConfirmation