import React from "react";
import "./Email_Icons_Info.scss";
import { HiOutlineMailOpen } from "react-icons/hi";
import {
  MdOutlineMarkEmailRead,
  MdOutlineMarkEmailUnread,
} from "react-icons/md";

import { TbMailPause, TbMailX, TbMailOff } from "react-icons/tb";
import { RiSpam3Fill } from "react-icons/ri";
import { IoLinkSharp } from "react-icons/io5";

const EmailIconsInfo = ({
  noEmail_send,
  email_link_click,
  email_open,
  email_delivered,
  email_bounced,
  email_spam_reported,
  email_dropped,
  email_deferred,
}) => {
  return (
    <div className="Email_Icons_Info">
      {
        noEmail_send && <IoLinkSharp className="noEmail_send_mini" title="Email nebyl automaticky odeslán" />
      }
      { !noEmail_send &&
        <>
          {/* delivered - pokud není (špatný email nebo nedoručený)*/}
          {
            !email_bounced && <MdOutlineMarkEmailRead
            style={{ color: email_delivered ? "green" : "#b4b4b4" }}
            title={email_delivered ? "Email doručen" : "Email nedoručen"}
          />
          }
          
          {/* open */}
          {
            !email_bounced && <HiOutlineMailOpen
            style={{ color: email_open > 0 ? "green" : "#b4b4b4" }}
            title={email_open > 0 ? "Email otevřen" : "Email neotevřen"}
          />
          }
          
          {/* Click */}
          {
            !email_bounced && <MdOutlineMarkEmailUnread
            style={{ color: email_link_click > 0 ? "green" : "#b4b4b4" }}
            title={
              email_link_click > 0
                ? "Na odkaz bylo kliknuto"
                : "Na odkaz nebylo kliknuto"
            }
          />
          }
          
          {/* deferred - dočasně odložen */}
          {email_deferred && !email_delivered && (
            <TbMailPause
              style={{ color: "red" }}
              title="Email dočasně odložen"
            />
          )}

          {/* bounce - nebyl přijat - plná schránka, špatná adresa */}
          {email_bounced && (
            <TbMailX
              style={{ color: "red" }}
              title={
                email_bounced
                  ? "Špatny E-mail nebo plná schránka"
                  : "Email a schránka v pořádku"
              }
            />
          )}

          {/* dropped - nebyl odeslán - blackslist, neaktivní */}
          {email_dropped && (
            <TbMailOff
              style={{ color: "red" }}
              title={
                email_dropped
                  ? "Jsme na black-list nebo je E-mail neaktivní dlouhodobě"
                  : "Nejsme na black-listu a E-mail je aktivní"
              }
            />
          )}

          {/* spamreport - Označen uživatelem jako spam */}
          <RiSpam3Fill
            style={{ color: email_spam_reported ? "red" : "#b4b4b4" }}
            title={
              email_spam_reported
                ? "Uživatelem označeno jako SPAM"
                : "Neoznačeno uživatelem jako SPAM"
            }
          />
        </>
      }
    </div>
  );
};

export default EmailIconsInfo;
