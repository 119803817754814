import { useContext } from "react"
import { GlobalContext } from "../../GlobalContext"
import "./scss/Footer.scss"

const Footer = () => {
  const {userName} = useContext(GlobalContext)
  return (
    <footer className={`${userName? "whiteBG" : ""}`}>
      {!userName && `© DMAPS ${new Date().getFullYear()}`}</footer>
  )
}

export default Footer