import React, { useState } from "react";
import "./Btn_Switch_On_Another_Tag.scss";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import PopUpMedium from "../../popUp/PopUp_Medium/PopUp_Medium";
// import BtnSwitchOnAnotherTagPopUp from "./Btn_Switch_On_Another_Tag_PopUp/Btn_Switch_On_Another_Tag_PopUp";

const BtnSwitchOnAnotherTag = ({/* name, tag_Name, respondent_ID, */ children }) => {
  const [show, setShow] = useState(false);
  

  return (
    <div className="Btn_Switch_On_Another_Tag">
      <button
        title="Přesunout do jiného tagu"
        onClick={() => setShow(true)}
        className="btn_Switch"
      >
        <HiOutlineSwitchHorizontal />
      </button>

      {show && (
        <PopUpMedium setClose={setShow} title="Přesunout do jiného tagu">
          {/* {children} */}
          {React.cloneElement(children, { setShow })}
        </PopUpMedium>
      )}
    </div>
  );
};

export default BtnSwitchOnAnotherTag;
