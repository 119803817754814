import { useContext, useState } from "react";
import { GlobalContext } from "../../../global/GlobalContext";
import "./NewRespondent_oneRespondent.scss";
import BtnClose from "../../elements/btn/BtnClose/BtnClose";
import { f_date_to_cz } from "../../../utils/date/f_date_to_cz";
import EmailIconsInfo from "../../elements/email/Email_Icons_Info";
import BtnDelete from "../../elements/btn/Btn_Delete/Btn_Delete";
import route_universal from "../../../routes/global/route_universal";
import NewRespondentNewEmailSend from "./NewRespondent_NewEmail_Send/NewRespondent_NewEmail_Send";
import InputLookText from "../../elements/inputs/InputLook_Text/InputLook_Text";
import BtnSwitchOnAnotherTag from "../../elements/btn/Btn_Switch_On_Another_Tag/Btn_Switch_On_Another_Tag";
import BtnSwitchOnAnotherTagPopUp from "../../elements/btn/Btn_Switch_On_Another_Tag/Btn_Switch_On_Another_Tag_PopUp/Btn_Switch_On_Another_Tag_PopUp";

const NewRespondentOneRespondent = ({
  fetch_data_newRespondent,
  setFetch_data_newRespondent,
  // color_sets_unfilled
  setFetch_result,
  setNewRespondent_ID,
  // tag list 
  setFetch_tags,
  tag_ID,
  setFetch_respondents_in_tag,
  // newRespondent list
  setFetch_result_NewRespondent_list,
}) => {
  const { setError, urlServer, admin_rank } = useContext(GlobalContext);

  const {
    _id,
    name,
    email,
    adminTag_create,
    code,
    number_attempts,
    company,
    tag,
    tag_profesion,
    date_create,
    // - email
    noEmail_send,
    email_link_click,
    email_open,
    email_delivered,
    email_bounced,
    email_spam_reported,
    email_dropped,
    email_deferred,
    // - email - date
    email_delivered_date,
    email_open_dates,
    email_link_click_dates,
    email_deferred_date,
    email_bounced_date,
    email_dropped_date,
    email_spam_report_date,
  } = fetch_data_newRespondent?.fetch_data;

  console.log(tag_profesion)
  // Number attempts
  const [number_attempts_edit, setNumber_attempts_edit] = useState(number_attempts)
  
  // -------------------------------------
  // f
    // delete NewRespondent
    const f_newRespondent_delete = async (set_show) => {
      try {
        const result = await route_universal(
          "DELETE",
          urlServer,
          "/newRespondent_delete",
          "newRespondent_ID",
          _id
        )

        if(result.delete) { // delete newRespodnent in list
          setFetch_result((prev) => ({
            ...prev,
            fetch_data: prev.fetch_data.filter((item) => item._id !== _id),
          }))

          // close Confirmation
          set_show(false);

          // close PopUp NewRespondent
          setNewRespondent_ID(false)
        }

        setError(result.msg)
        
      } catch (err) {
        setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
      }  
    }

    // edit number attempts
    const f_changeNumberAttempts = async (setShowEdit, set_original) => {
      if(number_attempts_edit >= 0) {
        try {
          const result = await route_universal(
            "PATCH",
            urlServer,
            "/edit_newRespondent_number_attempts",
            "newRespondent_ID",
            _id,
            "number_attempts_edit",
            number_attempts_edit
          )

          if(result.success) {
            setShowEdit(false)
            set_original(number_attempts_edit)
          }

          setError(result.msg)

        } catch (err) {
          setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
        } 
      } else {
        setError("Musí být zadáno alespoň 1 číslice (včetně 0)")
      }
    }
  // -------------------------------------


  return (
    <div className="NewRespondent_oneRespondent">
      {/* BTN Close */}
      <BtnClose setClose={setNewRespondent_ID} />
      {fetch_data_newRespondent && (
        <section className="NewRespondent_oneRespondent_main">
          <h1>Respondent</h1>

          <div className="Tag_Users">
            <div className="tag">
              <div
                className="tag_box"
                style={{ backgroundColor: adminTag_create.color }}
              >
                {/* Admin */}
                <div className="tagInfo_box_admin">
                  <p className="tagInfo_box_label">Vytvořil tag</p>
                  <p className="tag_name">{`${adminTag_create.name} ${adminTag_create.surname}`}</p>
                </div>

                {/* Tag Name */}
                <div className="tagInfo_box_tag">
                  <p className="tagInfo_box_label">Jméno Tagu</p>
                  <p className="tag">{tag.name}</p>
                </div>

                {/* Company*/}
                <div className="tagInfo_box_company">
                  <p className="tagInfo_box_label">Společnost</p>
                  <p className="company">{company.company}</p>
                </div>

                {/* Tag Create */}
                <div className="tagInfo_box_dateCreate">
                  <p className="tagInfo_box_label">Datum vytvoření tagu</p>
                  <p className="date">{f_date_to_cz(tag.date_create)}</p>
                </div>

                {/* Delete NewRespondent */}
                  {
                    (admin_rank === 0 || admin_rank === 3) && <div className="newRespondent_delete">
                      <BtnDelete 
                        title="Smazat nevyplněného respondenta"
                        h1="Smazání nevyplněného respondenta" 
                        name={name} 
                        text="Jste si jistí, že chcete smazat nevyplněného respondenta?" 
                        btn_yes={f_newRespondent_delete}
                      />
                    </div>
                  }

                  {/* New Email send */}
                  <div className="newEmail_send">
                      <NewRespondentNewEmailSend newResponse_ID={_id} />
                  </div>

                  {/* Btn_Switch_On_Another_Tag */}
                  <div className="Btn_Switch_On_Another_Tag">
                     <BtnSwitchOnAnotherTag>
                      <BtnSwitchOnAnotherTagPopUp 
                        // basic set
                        name={name} 
                        tag_Name={tag.name} 
                        respondent_ID={_id} 
                        // color_sets_unfilled
                        set_list={setFetch_result}
                        set_one_respondent={setFetch_data_newRespondent} 
                        // tags
                        setFetch_tags={setFetch_tags}
                        tag_ID={tag_ID}
                        setFetch_respondents_in_tag={setFetch_respondents_in_tag}
                        // newRespondent list
                        setFetch_result_NewRespondent_list={setFetch_result_NewRespondent_list}
                      />
                    </BtnSwitchOnAnotherTag>
                  </div>
              </div>
            </div>
          </div>

          <div className="NewRespondent_oneRespondent_main_main">
            <div className="NewRespondent_oneRespondent_data">
              {/* Date create + number attempts */}
              <div className="date_attempts">
                {/* date_create */}
                <div className="date_create">
                  <p className="date_create_title">Datum vytvoření</p>
                  <p className="date_create_date">{f_date_to_cz(date_create)}</p>
                </div>

                {/* UserData */}
                <div className="name_email">
                  <p className="name">{name}</p>
                  <p className="email">{email}</p>
                </div>

                <div className="number_attempts" >
                  {/* <p className="date_create_title">Datum vytvoření</p> */}
                  <InputLookText
                      type="text"
                      label=""
                      id="newRespondent_numberAttempts"
                      value={number_attempts_edit}
                      set={setNumber_attempts_edit}
                      regexSetting="number"
                      btnText="Editovat"
                      btnFunction={f_changeNumberAttempts}
                      original_text={number_attempts}
                      // show={edit_tagName}
                      // setShow={setEdit_tagName}
                      labelBox="Počet průchodů"
                      title="Změnit počet průchodů"
                    />
                </div>
              </div>
              
              

              {/* Email icons */}
              <div className="email_box">
                <EmailIconsInfo
                  noEmail_send={noEmail_send}
                  email_link_click={email_link_click}
                  email_open={email_open}
                  email_delivered={email_delivered}
                  email_bounced={email_bounced}
                  email_spam_reported={email_spam_reported}
                  email_dropped={email_dropped}
                  email_deferred={email_deferred}
                  // - email - date
                  email_delivered_date={email_delivered_date}
                  email_open_dates={email_open_dates}
                  email_link_click_dates={email_link_click_dates}
                  email_deferred_date={email_deferred_date}
                  email_bounced_date={email_bounced_date}
                  email_dropped_date={email_dropped_date}
                  email_spam_report_date={email_spam_report_date}
                />
              </div>
            </div>

            <div className="others_data">
                {
                  tag_profesion.tag_profesion_Name && <div className="code_box">
                   <div className="tag_position">
                      <div>
                        <p className="code_title">Pozice</p>
                        <p className="position_text">{tag_profesion.tag_profesion_Name}</p>
                      </div>
                      <div>
                        <p className="code_title">URL pozice</p>
                        <p className="position_text">{tag_profesion.tag_profesion_URL}</p>
                      </div>
                    </div>
                  </div>
                }
                <div className="code_box">
                  <p className="code_title">Kód</p>
                  <p className="code_data">{code}</p>
                </div>
              </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default NewRespondentOneRespondent;
