import { useState, useEffect } from "react";
import "./UsersData.scss";
import { useParams } from 'react-router-dom';
import UserDataListFromLastPageScroll from "../../../components/completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import SearchFullResult from "../../../components/elements/Search_FullResult/Search_FullResult";
import UsersDataResult from "../../../components/pages/UsersData/UsersData_Result/UsersData_Result";


const UsersData = () => {
  const { userID } = useParams();
  const [URLValue, setURLValue] = useState("")
  
  const [userName, setUserName] = useState("");
  const [fetch_result, setFetch_result] = useState([]);

  // scroll
  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(10);

  console.log(userName)
  console.log(fetch_result)
  // -------------------------------
  // hooks

  // - set userID from URL
  useEffect(() => {
    if (userID && !userName) {
      setURLValue(userID); // nastavíme ID do vyhledávacího inputu
    }
  }, [userID, userName]);

  // ---------------------------------------

  return (
    <div className="UsersData">
      <div className="UserData_main">
        <section className="title">
          <h1>Uživatelská data</h1>
        </section>

        <section className="search">
          {/* <Search
            inputType="text"
            inputLabel="Zadejte jméno respondenta"
            inputID="userName"
            inputRegex="ID"
            state={userName || URLValue}
            setState={(value) => {
              setUserName(value); // Nastavíme userName
              if (value === "") setURLValue(""); // Pokud je input prázdný, smažeme URLValue
            }}
            wordResultKey={userName ? "name" :"userID"}
            urlFetch="/findUserName"
            route={route_findUserAndColorSet}
            setFetch_result={setFetch_result}
            autoComplete="off"
            page={page}
            limitResponse={limitResponse}
          /> */}
          <SearchFullResult
            inputType="text"
            inputLabel="Zadejte jméno respondenta"
            inputID="ID"
            inputRegex="tag"
            state={userName || URLValue}
            setState={(value) => {
              setUserName(value); // Nastavíme userName
              if (value === "") setURLValue(""); // Pokud je input prázdný, smažeme URLValue
            }}
            wordResultKey={userName ? "name" :"userID"}
            urlFetch="/findUserName"
            autoComplete="off"
            setFetch_result={setFetch_result}
            wordResultKey2="page"
            state2={page}
            wordResultKey3="limit"
            state3={limitResponse}
          />
        </section>

        <section className="result">
            {
              fetch_result?.fetch_data?.length > 0 && 
              fetch_result?.fetch_data.map((oneUser) => {
                return <UsersDataResult 
                key={oneUser._id} 
                setUserName={setUserName} 
                oneUser={oneUser} 
                // for delete user in (OneColorSet)
                setFetch_result={setFetch_result}
                fetch_result={fetch_result} 
                URLValue={URLValue}
                setURLValue={setURLValue}
                userName={userName}
              />
                
              }) 
            }
        </section>
      </div>
  
      {/* Page Scroller */}
      <section className="pageScroll">
        {fetch_result.totalPages && (
          <UserDataListFromLastPageScroll
            fetch_result={fetch_result}
            page={page}
            setPage={setPage}
            limitResponse={limitResponse}
            setLimitRespose={setLimitRespose}
          />
        )}
      </section>
    </div>
  );
};

export default UsersData;
