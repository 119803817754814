import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../global/GlobalContext";
import route_post_universal from "../../routes/global/route_post_universal";

export const useOneNewRespondent = ( newRespondent_ID ) => {
  const { urlServer, setError, setLoading_user } = useContext(GlobalContext);
  const [fetch_data_newRespondent, setFetch_data_newRespondent] = useState({});

  useEffect(() => {
    const fetchColorSet = async () => {
      // if (!newRespondent_ID) return;
      if (!newRespondent_ID || fetch_data_newRespondent?.id === newRespondent_ID) return;
      setFetch_data_newRespondent({});
      setLoading_user(true); // Loading
      
      try {
        const result = await route_post_universal(
          urlServer,
          "/find_newRedpondent_one",
          "newRespondent_ID",
          newRespondent_ID
        );

        setFetch_data_newRespondent(result);
      } catch (error) {
        setError("Error fetching color set:", error);
      } finally {
        setLoading_user(false)
      }
    };

    fetchColorSet();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRespondent_ID]);

  return [fetch_data_newRespondent, setFetch_data_newRespondent];
};