import { useEffect, useState, useContext } from "react";
import "./Tag_Users.scss";

import { GlobalContext } from "../../../../../global/GlobalContext";
import BtnClose from "../../../../elements/btn/BtnClose/BtnClose";
import route_post_universal from "../../../../../routes/global/route_post_universal";
import { f_date_to_cz } from "../../../../../utils/date/f_date_to_cz";
import TagUsersResponse from "./Tag_Users_Response/Tag_Users_Response";
import UserDataListFromLastPageScroll from "../../../../completeComponents/UserData_listFrom_last_result/UserData_listFromLast_pageScroll/UserData_listFromLast_pageScroll";
import CheckBox from "../../../../elements/checkBox/CheckBox";
import route_universal from "../../../../../routes/global/route_universal";
import { f_checkBox_update_parant } from "../../../../../utils/checkBox/f_checkBox_update_parant";
import InputContainer from "../../../../elements/inputs/InputContainer/InputContainer";
import InputLookText from "../../../../elements/inputs/InputLook_Text/InputLook_Text";
import BtnDelete from "../../../../elements/btn/Btn_Delete/Btn_Delete";

const TagUsers = ({ 
  tagData, 
  setSelectedTagID, 
  setFetch_tags,
  // - from NewRespondent - list
  setFetch_result_NewRespondent_list,
  // page colorSet - over tag - switch to another tag
  setFetch_result_ColorSet_list
}) => {

  console.log(tagData)

  const { urlServer, setError, admin_rank, setLoading_tagUsers } = useContext(GlobalContext);
  // new fetch
  const [fetch_result, setFetch_result] = useState([]);

  const [page, setPage] = useState(1);
  const [limitResponse, setLimitRespose] = useState(25);

  const [private_chackBox, setPrivate_chackBox] = useState(
    tagData.private_chackBox
  );
  const [lock_chackBox, setLock_chackBox] = useState(tagData.lock_chackBox);

  // tag position
  const [tag_positionName, setTag_positionName] = useState(
    tagData.job_position
  );
  const [tag_positionURL, setTag_positionURL] = useState(tagData.job_URL);
  const [tag_btnPositionEdit, setTag_btnPositionEdit] = useState(false);

  // tag name edit
  const [tagName, setTagName] = useState(tagData.name);

  // ---------------------
  // custom hook
  useEffect(() => {
    const fetchData = async () => {
      setLoading_tagUsers(true) // Loading
      try {
        const response = await route_post_universal(
          urlServer,
          "/find_tag_users_listFromLast",
          "limit",
          limitResponse, // limit on page
          "page",
          page,
          "tag_ID",
          tagData._id
        );

        if (response.success) {
          setFetch_result(response); // Nastavení dat do stavu
        } else {
          setError(response.msg)
        }
      } catch (error) {
        setError("Chyba při načítání dat:", error);
      } finally {
        setLoading_tagUsers(false)
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlServer, limitResponse, page, tagData._id]);

  // ---------------------
  // f
  // checkBox
  const checkBox_private = async (field) => {
    const value = field === "private" ? !private_chackBox : !lock_chackBox; // Změníme hodnotu příslušného checkboxu

    const response = await route_universal(
      "PUT",
      urlServer,
      "/tag_update_private_checkBox",
      "tag_ID",
      tagData._id,
      field,
      value
    );

    if (response.success) {
      // setError("Soukromý tagu aktualizováno:", response.updatedFields);

      // Dynamicky aktualizujeme stav podle změněného pole
      if (field === "private") {
        setPrivate_chackBox(value);
      } else if (field === "lock") {
        setLock_chackBox(value);
      }

      // Aktualizace parent datové struktury
      f_checkBox_update_parant(
        setFetch_tags,
        tagData._id,
        `admin_create.${field}`,
        value
      );
    } 
    
    setError(response.msg);
  };

  // ----------

  // btn Edit position
  const btn_editPosition = async () => {
    if (tag_btnPositionEdit) {
      const response = await route_universal(
        "PATCH",
        urlServer,
        "/tag_update_position",
        "tag_ID",
        tagData._id,
        "job_position",
        tag_positionName,
        "job_URL",
        tag_positionURL
      );

      if (response.success) {
        setPrivate_chackBox(!private_chackBox);
        f_checkBox_update_parant(
          setFetch_tags,
          tagData._id,
          "admin_create.private",
          !private_chackBox
        );
      } 

      setError(response.msg);
    }

    setTag_btnPositionEdit(!tag_btnPositionEdit);
  };

  // change Company Name
  const f_changeTagName = async (setShowEdit, set_original) => {
    if(tagName.length > 3) {
      try {
        const result = await route_universal(
          "PATCH",
          urlServer,
          "/tag_update_name",
          "tag_ID",
          tagData._id,
          "tag_Name",
          tagName
        );
    
        if(result?.succes){
          setShowEdit(false)
          set_original(tagName)
          // setOriginal_nameTag(tagName);
          // setEdit_tagName(false);
  
          // set tag Name in list
          setFetch_tags((prev) => ({
            ...prev,
            fetch_data: prev.fetch_data.map((item) =>
              item._id === tagData._id ? { ...item, name: tagName } : item
            ),
          }));
  
        } 

        setError(result.msg)

      } catch (err) {
        setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
      } 
    } else {
      setError("Jméno společnosti musí být alepsoň 4 znaky dlouhé")
    }
  }

  // Delete tag
  const f_tag_delete = async ( set_show ) => {
    try {
      const result = await route_universal(
        "DELETE",
        urlServer,
        "/tag_delete",
        "tag_ID",
        tagData._id,
      )

      if(result.delete) { // delete tag in list === (tag is empty)
        setFetch_tags((prev) => ({
          ...prev,
          fetch_data: prev.fetch_data.filter((item) => item._id !== tagData._id),
        }));
        // close Confirmation
        set_show(false);
        // close PopUp Tag
        setSelectedTagID(false);
        
      } else if(result.full) {
        set_show(false)
      }

      setError(result.msg)

    } catch (err) {
      setError(`FrontEnd chyba: ${err.message}. Kontaktujte nás. Váš Novitim.`)
    } 
  }

  // ---------------------

  return (
    <div className="PopUp_full">
      <div className="popUp_full_window">
        {/* BTN CLOSE */}
        <BtnClose setClose={setSelectedTagID} />

        {/* Title */}
        <div className="popUp_full_title">
          <h2>Uživatelé v tagu</h2>
        </div>

        {/* Main */}
        <div className="popUp_full_main">
          <div className="Tag_Users">
            <div className="tag">
              
              <div
                className="tag_box"
                // style={{ backgroundColor: tagData.admin_color }}
              >
                {/* Tag Name */}
                <div className="tag_name_box">
                  
                  {
                  (admin_rank === 0 || admin_rank === 3) && <div className="delete">
                    <BtnDelete 
                      title="Smazat tag"
                      h1="Smazání tagu" 
                      name={tagName} 
                      text="Jste si jistí, že chcete smazat tag?" 
                      btn_yes={f_tag_delete}
                    />
                    </div>
                  }

                  <div className="tag_name"
                    // onClick={() => setEdit_tagName(true)}
                    title="Změnit jméno Tagu"
                  >
                    <InputLookText
                      type="text"
                      label="Editace jména tagu"
                      id="tagName_change_input"
                      value={tagName}
                      set={setTagName}
                      regexSetting="universal"
                      btnText="Editovat"
                      btnFunction={f_changeTagName}
                      original_text={tagData.name}
                      // show={edit_tagName}
                      // setShow={setEdit_tagName}
                    />
                  </div>
                  
                  <div className="white-space"></div>
                </div>

                <div className="tagInfoData">
                  {/* CheckBox - Private */}
                  <div className="private_chackBox">
                    {/* <CheckBox value={lock_chackBox} set={checkBox_private} text={["Uzamčený výstup","Odemčený výstup"]} /> */}
                    <CheckBox
                      value={lock_chackBox}
                      set={() => checkBox_private("lock")}
                      text={["Zamknuté výstupy", "Odemknuté výstupy"]}
                    />
                  </div>

                  {/* Admin */}
                  <div 
                    className="tagInfo_box_admin"
                    style={{ border: `5px solid ${tagData.admin_color}` }}
                  >
                    <p className="tagInfo_box_label">Vytvořil</p>
                    <p className="tag_name">{tagData.admin_name}</p>
                  </div>

                  

                  {/* Company*/}
                  <div className="tagInfo_box_company">
                    <p className="tagInfo_box_label">Společnost</p>
                    <p className="company">{tagData.company_Name}</p>
                  </div>

                  {/* Tag Create */}
                  <div className="tagInfo_box_dateCreate">
                    <p className="tagInfo_box_label">Datum vytvoření</p>
                    <p className="date">{f_date_to_cz(tagData.date_create)}</p>
                  </div>

                  {/* CheckBox - Private */}
                  <div className="private_chackBox">
                    {/* <CheckBox
                      value={private_chackBox}
                      set={checkBox_private}
                      text={["Soukromí", "Veřejný"]}
                    />
                    */}
                    <CheckBox
                      value={private_chackBox}
                      set={() => checkBox_private("private")}
                      text={["Soukromý tag", "Veřejný tag"]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="tag_position">
              <InputContainer
                type="text"
                label="Jméno pozice"
                id="position_name"
                value={tag_positionName}
                set={setTag_positionName}
                regexSetting="tag"
                readOnly={!tag_btnPositionEdit ?true : false}
              />
              <InputContainer
                type="text"
                label="URL pozice"
                id="position_URL"
                value={tag_positionURL}
                set={setTag_positionURL}
                regexSetting="URL"
                readOnly={!tag_btnPositionEdit ?true : false}
              />
              <button
                className="normalButton btn_editTagPosition"
                onClick={btn_editPosition}
              >
                {!tag_btnPositionEdit ? "Editovat" : "Potvrdit"}
              </button>
            </div>
          </div>

          {/* Tag's user response */}
          <TagUsersResponse
            fetch_result={fetch_result}
            setFetch_result={setFetch_result}
            // tag_list
            tag_ID={tagData._id}
            setFetch_tags={setFetch_tags}
            // - page NewRespondent_list - over tag - switch to another tag
            setFetch_result_NewRespondent_list={setFetch_result_NewRespondent_list}
            // page colorSet - over tag - switch to another tag
            setFetch_result_ColorSet_list={setFetch_result_ColorSet_list}
          />

          {/* Page Scroller */}
          <section className="pageScroll">
            {fetch_result.totalPages && (
              <UserDataListFromLastPageScroll
                fetch_result={fetch_result}
                page={page}
                setPage={setPage}
                limitResponse={limitResponse}
                setLimitRespose={setLimitRespose}
              />
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default TagUsers;
